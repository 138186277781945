import React from "react";
import Image from "next/image";
import {Box} from "@chakra-ui/react";

type Props = {image: string};

const CustomerCard = (props: Props) => {
  const {image} = props;
  return (
    <Box p={4}>
      <Box height={"120px"} position={"relative"} w="full">
        <Image
          alt=""
          fill
          src={image}
          style={{objectFit: "contain", objectPosition: "center"}}
        />
      </Box>
    </Box>
  );
};

export default CustomerCard;
