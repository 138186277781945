export const CUSTOMER_CONSTANTS = {TITLE: "Our Customers & Partners"};

export const CUSTOMER_LIST = [
  "/customers/actual-experience.webp",
  "/customers/adobe.webp",
  "/customers/ameris-bank.webp",
  "/customers/aruba.jpg",
  "/customers/bayinfo-tech.webp",
  "/customers/capgemini.jpg",
  "/customers/choice-hotels.webp",
  "/customers/cisco.webp",
  "/customers/Cogent.webp",
  "/customers/cologix.webp",
  "/customers/dell.webp",
  "/customers/dimension-data.webp",
  "/customers/dyntek.webp",
  "/customers/execute-center.webp",
  "/customers/facebook.webp",
  "/customers/finning-cat.webp",
  "/customers/fortinet.webp",
  "/customers/georgia-tech.jpg",
  "/customers/global-knowledge.webp",
  "/customers/hp.webp",
  "/customers/hughes-net.webp",
  "/customers/hurricane-electric.jpg",
  "/customers/ieee.jpg",
  "/customers/itpro-tv.webp",
  "/customers/konnected-concepts.jpg",
  "/customers/lcm-group.webp",
  "/customers/lumen.jpg",
  "/customers/march-dimes.webp",
  "/customers/masergy.webp",
  "/customers/microsoft.webp",
  "/customers/niit-tech.webp",
  "/customers/nintendo.webp",
  "/customers/paloalto.jpg",
  "/customers/paytm.webp",
  "/customers/providence.jpg",
  "/customers/presidio.webp",
  "/customers/range-resources.webp",
  "/customers/roche.webp",
  "/customers/sait.webp",
  "/customers/seabreeze.webp",
  "/customers/silver-peak.webp",
  "/customers/sycomp.webp",
  "/customers/training-world.webp",
  "/customers/us-water.webp",
  "/customers/utah-univercity.webp",
  "/customers/webhelp.webp",
  "/customers/yealink.webp",
  "/customers/yeastar.webp",
  "/customers/zeus-logo.webp",
];

export const CUSTOMER_LIST1 = [
  "/customers/actual-experience.webp",
  "/customers/adobe.webp",
  "/customers/ameris-bank.webp",
];
