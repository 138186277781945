import {Stack} from "@chakra-ui/react";
import Title from "@/components/title";
import CustomersList from "./components/customersList";
import {CUSTOMER_CONSTANTS} from "./customers.constants";

const Customers = () => {
  return (
    <Stack spacing={8}>
      <Title highlight={"Partners"} title={CUSTOMER_CONSTANTS.TITLE} />
      <CustomersList />
    </Stack>
  );
};

export default Customers;
