import {useBreakpointValue} from "@chakra-ui/react";

export const useCustomerCarousel = () => {
  const slidesToShow = useBreakpointValue({base: 1, sm: 3, md: 4, lg: 6});
  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    centerMode: false,
    infinite: true,
    pauseOnHover: true,
    slidesToScroll: 1,
    slidesToShow: slidesToShow,
    swipeToSlide: true,
  };

  return {
    settings,
  };
};
