"use client";
import React, {useState} from "react";
import {Box, HStack, IconButton, VStack} from "@chakra-ui/react";
import {HiArrowLeft, HiArrowRight} from "@onemind-services-llc/react-icons-ng/hi";
import {Carousel} from "@onemind-services-llc/ui-components";
import CustomerCard from "./customerCard";
import {useCustomerCarousel} from "../hooks/useCustomerCarousel";
import {CUSTOMER_LIST} from "../customers.constants";

const CustomersList = () => {
  const [carouselActions, setCarouselActions] = useState({
    slickPrev: () => {},
    slickNext: () => {},
  });
  const {slickNext, slickPrev} = carouselActions;
  const {settings} = useCustomerCarousel();
  return (
    <VStack spacing={4}>
      <Box mx={{sm: -4}} w="full">
        <Carousel settings={settings} setSliderRef={setCarouselActions}>
          {CUSTOMER_LIST.map((customerImage, index) => {
            return (
              <Box key={index} px={4}>
                <CustomerCard image={customerImage} />
              </Box>
            );
          })}
        </Carousel>
      </Box>
      <HStack spacing={6}>
        <IconButton
          aria-label="previous"
          color="brand.100"
          fontSize="30px"
          icon={<HiArrowLeft />}
          onClick={slickPrev}
          variant={"ghost"}
        />
        <IconButton
          aria-label="next"
          color="brand.100"
          fontSize="30px"
          icon={<HiArrowRight />}
          onClick={slickNext}
          variant={"ghost"}
        />
      </HStack>
    </VStack>
  );
};

export default CustomersList;
